<template>
    <div class="rightSide">
      <div class="rightSide_inner">
        <div class="rightSide_inner_title">
            <span style="font-size: 30px; color: #333333">
              {{ $t("Display Competition") }}
            </span>
        </div>
        <!-- tab面板 -->
        <el-tabs v-model="activeName" type="card" >
          <!-- 列表 -->
          <el-tab-pane :label="$t('Excellent Display Exhibition')" name="first">
            <p style="font-size: 20px; color: #333333; margin-top: 10px;text-align: center;">{{ $t("2023 Q2 Overseas Store Display Competition Quarterly Evaluation") }}</p>
            <div v-for="(item,index) in tableGoodsData" :key="index" class="text item">
              <el-card class="box-card">
                <el-row style="margin-bottom: 40px;">
                  <el-col :span="8" style="text-align: center;">
                    {{ $t("Store Name") }}： {{item.supervisor_store_name }}
                  </el-col>
                  <el-col :span="8" style="text-align: center;">
                    {{ $t("Opening date") }}： {{item.supervisor_opening_date? new Date(item.supervisor_opening_date * 1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit'}).replace(/\//g,'-'): ''}}
                  </el-col>
                  <el-col :span="8" style="text-align: center;">
                    {{ $t("Address") }}： {{item.supervisor_address }}
                  </el-col>
                </el-row>
                <el-divider>
                  <template v-if="index===0">
                    <el-image style="width: 60px; height: 60px" :src="require('@/assets/images/displaycompet/1.png')" ></el-image>
                    <span style="font-size: 18px;float: right;margin: 20px 0;">{{ $t("First Place") }}</span>
                  </template>
                  <template v-if="index===1">
                    <el-image style="width: 60px; height: 60px" :src="require('@/assets/images/displaycompet/2.png')"></el-image>
                    <span style="font-size: 18px;float: right;margin: 20px 0;">{{ $t("Second Place") }}</span>
                  </template>
                  <template v-if="index===2">
                    <el-image style="width: 60px; height: 60px" :src="require('@/assets/images/displaycompet/3.png')" ></el-image>
                    <span style="font-size: 18px;float: right;margin: 20px 0;">{{ $t("Third Place") }}</span>
                  </template>
                  <template v-if="index>2">
                    <i class="el-icon-picture" style="font-size: 38px;"></i>
                  </template>
               </el-divider>
                <!-- 图片 -->
                <el-row>
                  <el-col :span="8" v-for="(itemImg,index) in item.ImageArr" :key="index" style="text-align: center;">
                    <el-image v-if="itemImg.is_as_good_show === 1" style="width: 300px; height: 300px;margin: 5px;" :src="itemImg.img_url" alt="" :preview-src-list="item.BigImageArr"></el-image>
                  </el-col>
                </el-row>
              </el-card>
            </div>
          </el-tab-pane>
          <!-- 填写表单 -->
          <el-tab-pane :label="$t('Display Picture Submission')" name="second">
              <p style="font-size: 20px; color: #333333; margin-top: 10px">{{ $t("Display Picture Submission") }}.</p>
              <p style="font-size: 14px; color:red;">({{ $t("Kind reminder: Items marked with * are required") }})</p>

              <!-- 填写表单 -->
              <div class="input">
                <div class="input_info">
                  <p class="input_text"><span style="color: red;">*</span>{{ $t("Store Name") }}：</p>
                  <el-input v-model="formData.store_name" :placeholder="$t('Please enter the store name')"
                            size="middle" class="input_select" clearable  />
                </div>

                <div class="input_info">
                  <p class="input_text"><span style="color: red;">*</span>{{ $t("Supervisor") }}：</p>
                  <el-input v-model="formData.supervisor" :placeholder="$t('Please enter the supervisor')"
                            size="middle" class="input_select" clearable  />
                </div>
        
                <div class="input_info">
                  <p class="input_text"><span style="color: red;">*</span>{{ $t("Opening date") }}：</p>
                  <el-date-picker
                      v-model="formData.opening_date"
                      type="date"
                      value-format="yyyy-MM-dd"
                      class="input_select"
                      :placeholder="$t('Please select the opening date')" clearable>
                  </el-date-picker>
                </div>
        
                <div class="input_info">
                  <p class="input_text"><span style="color: red;">*</span>{{ $t("Address") }}：</p>
                  <el-input v-model="formData.address" :placeholder="$t('Please enter address')" type="textarea" size="middle" class="input_select" clearable />
                </div>
        
                <div class="input_info" style="width: 100%">
                  <p class="input_text">{{ $t("Store display photos") }}：</p>
                  <el-upload class="upload-demo"
                      ref="uploadImgEl"
                      :action="uploadPostUrl"
                      :on-preview="handlePreview"
                      list-type="picture-card"
                      :multiple="true"
                      :auto-upload="false"
                      :file-list="uploadImgList"
                      :on-exceed="handleExceed"
                      :limit="uploadImgLimit"
                      :on-change="handleFileChange"
                      :before-remove="handleImgBeforeRemove"
                      >
                      <i class="el-icon-plus" style="width: 50px; height: 50px;"></i>
                    </el-upload>
                    <!--            图片放大预览-->
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  <div style="margin: 10px">
                    <span >{{ $t('Note: The size of each uploaded image should not exceed 10M, and the total number should not exceed 10') }}</span>  
                  </div>
                </div>

                <!-- 视频 -->
                <div class="input_info" style="width: 100%">
                  <p class="input_text">{{ $t("Store display videos") }}：</p>
                  <el-upload class="upload-demo"
                      ref="uploadVideoEl"
                      :action="uploadPostUrl"
                      :on-preview="handleVideoPreview"
                      list-type="text"
                      :multiple="true"
                      :auto-upload="false"
                      :file-list="uploadVideoList"
                      :on-exceed="handleExceedVideo"
                      :limit="uploadVideoLimit"
                      :on-change="handleVideoFileChange"
                      :before-remove="handleVideoBeforeRemove"
                      >
                      <i class="el-icon-plus" style="width: 50px; height: 50px;    padding: 16px;border: 1px dashed #c0ccda;"></i>
                      <!-- <video width="100%" height="100%" v-for="(video,index) in uploadVideoList" :key="index" controls>
                          <source :src="video.url" type="video/mp4" />
                          您的浏览器不支持 video 标签。
                      </video> -->
                    </el-upload>
                    <!--            图片放大预览-->
                    <el-dialog :visible.sync="dialogVideoVisible">
                          <video width="100%" height="100%" controls>
                            <source :src="dialogVideoUrl" type="video/mp4" />
                            您的浏览器不支持 video 标签。
                          </video>
                      </el-dialog>
                  <div style="margin: 10px">
                    <span >{{ $t('Note: Optional item, the number of videos should not exceed 1, and the size should not exceed 300M') }}</span>  
                  </div>
                </div>
        

        
              </div>
              <!-- 填写表单·提交按钮 -->
              <div class="input" style="margin-left: 130px">
                <el-button type="primary" size="medium" style="background: #238da5" @click="submitApp" >
                  {{ $t("Submit") }}
                </el-button>          
              </div>
              <!--  添加的列表  -->
              <div style="border:1px solid #238da5; margin-top: 10px;">
                <el-table :data="tableData" height="300" border style="width: 100%">
                  <el-table-column prop="store_name" :label="$t('Store Name')" width="90" align="center">
                  </el-table-column>
                  <el-table-column prop="supervisor" :label="$t('Supervisor')" width="80" align="center">
                  </el-table-column>
                  <el-table-column prop="opening_date" :label="$t('Opening date')" width="95" align="center">
                    <template slot-scope="scope">
                      {{scope.row.opening_date? new Date(scope.row.opening_date * 1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit'}).replace(/\//g,'-'): ''}}
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" :label="$t('Address')" align="center">
                  </el-table-column>
                  <el-table-column :label="$t('Store display photos')" align="center">
                    <template v-slot="scope">
                      <el-image style="width: 50px; height: 50px;margin: 5px;" :src="item.img_url" v-for="(item,index) in scope.row.ImageArr" :key="index" alt="" :preview-src-list="scope.row.BigImageArr"></el-image>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Store display videos')" align="center">
                    <template v-slot="scope">
                      <el-link  v-for="(item,index) in scope.row.VideoArr" :key="index" style="text-align: left;" @click="tableDataVideoPreview(item.video_url)" >
                        {{ item.video_url.split('/')[item.video_url.split('/').length-1] }}
                      </el-link><br/>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Submission time')" align="center" width="90">
                    <template slot-scope="{row}">
                      {{ new Date(new Date(row.created_at).getTime()).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-') }}
                    </template>
                  </el-table-column>
                  <!--  -->
                  <el-table-column prop="sku" :label="$t('Operation')" align="center" width="100">
                    <template v-slot="scope">
                      <template v-if="scope.row.is_editable === 1">
                        <el-button @click.native.prevent="editRow(scope.$index, tableData)" type="text" size="small" style="color: rgb(35, 141, 165);">
                          {{ $t("Edit") }}
                        </el-button>
                        <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small" style="color:red;">
                          {{ $t("Delete") }}
                        </el-button>
                      </template>
                    </template>
                  </el-table-column>

                </el-table>
              </div>
              <!-- 分页 -->
              <div class="footer">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="pageInfo.page" :page-size="pageInfo.pageSize"
                  layout="total,  prev, pager, next, jumper" :total="pageInfo.total" background>
                </el-pagination>
              </div>  
          </el-tab-pane>
        </el-tabs>


      <!--  -->
      <div style="margin-bottom: 30px;height: 30px;">&nbsp;</div>
      </div>

  
    </div>
  </template>
  <script>
  import applyAfterSalesList from "./js/index";
  
  export default applyAfterSalesList
  </script>
  <style lang="scss" scoped>
  .rightSide {
    // width: 1320px;
    //overflow-y: scroll;
    margin: 15px 0 0 20px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  
    &_inner {
      width: 1218px;
      &_title {
        width: 1218px;
        height: 68px;
        margin-top: 30px;
      }
    }
  }
  
  .table_style {
    width: 1218px;
    height: 68px;
    margin-top: 30px;
  }
  
  .footer {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
  
    &_info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
      width: 500px;
    }
  
    &_text {
      width: 200px;
      flex-shrink: 0;
      text-align: right;
    }
  
    &_select {
      width: 270px;
      flex-shrink: 0;
    }
  }
  </style>
  <style scoped>
  .el-radio /deep/ .el-radio__label {
    font-size: 18px;
  }

  .el-textarea /deep/ .el-textarea__inner{
    font-family: auto;
  }
  </style>
  