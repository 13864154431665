import axios from "axios";

export default {
  get: (page, pagesize,list_type) => {
    const params = {
      page: page,
      pagesize: pagesize,
      list_type:list_type
    };
    return axios.get("/w1/displaycompetition/getList", { params: params });
  },
  add: (params) => {
    return axios.post("/w1/displaycompetition/addDC", params);
  },
  edit: (params) => {
    return axios.put("/w1/displaycompetition/editDC", params);
  },
  del: (id) => {
    return axios.delete("/w1/displaycompetition/deleteDC/"+id);
  },
  DeleteTestImg:(params)=>{
    return axios.put("/w1/displaycompetition/deleteDCTestImg", params);
  },
  deleteImg:(params)=>{
    return axios.put("/w1/displaycompetition/deleteDCImg", params);
  },
  DeleteTestVideo:(params)=>{
    return axios.put("/w1/displaycompetition/deleteDCTestVideo", params);
  },
  deleteVideo:(params)=>{
    return axios.put("/w1/displaycompetition/deleteDCVideo", params);
  },
  uploadApi:(url,data,token) =>{
    return axios.post(url,data,{headers: {'Authorization':token }});
  }
};
