import DCapi from "@/api/web/displaycompetition/displayCompeti";


export default {
  components: {},
  data() {
    return {
      // tab标签页激活面板
      activeName: 'first',
        // 图片处理相关
      uploadPostUrl: this.$root.DownUrl+'/w1/upload/multFile', // 图片上传地址,
      uploadImgLimit: 10, //图片上传限制数量
      uploadVideoLimit: 1, //视频上传限制数量
    //   表单
      formData:{
        id:0,
        store_name:"",
        supervisor:"",
        opening_date:"",
        address:"",
        uploadImgList: [], // 商品图片
        uploadVideoList:[],//视频
      },
      pageInfo: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
      uploadImgList:[], //上传成功的图片
      UploadReadyImgList:[],//待上传的图片
      uploadVideoList:[],//上传成功的视频
      UploadReadyVideoList:[],//待上传的视频
      tableData:[],
      tableGoodsData:[],//优秀作品
      // 预览图片
      dialogImageUrl: '',
      dialogVisible: false,
      // 预览视频
      dialogVideoUrl: '',
      dialogVideoVisible:false,
      disabled: false,
      acceptImgType:["bmp","jpg","jpeg","png","webp"],//图片格式限制
      acceptVideoType:["mp4"],//视频格式限制
      lang: "en",
      // 是否重复提交
      isRepeat:false
    };
  },
  created() {
    this.lang=window.sessionStorage.getItem("lg") || "en";
    this.clearImages() //清空旧数据
    this.clearVideos() //清空旧数据
    this.getTableData()
    this.getGoodsDisplayCompetition() //获取优秀作品
  },
  methods: {
    // 翻页
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
          this.getPresellOrder();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
          this.getPresellOrder();
    },
    
    /* 获取优秀作品 */
    getGoodsDisplayCompetition(){
      DCapi.get(this.pageInfo.page, this.pageInfo.pagesize,1 ).then((res) => {
        this.tableGoodsData=res.data.data.data_list?res.data.data.data_list:[];
        // 设置图片预览
        if (this.tableGoodsData.length >0) {
          for (const key in this.tableGoodsData) {
            if (typeof(this.tableGoodsData[key].BigImageArr) === "undefined") {
              this.tableGoodsData[key].BigImageArr = []
            }
            for (const key2 in this.tableGoodsData[key].ImageArr) {
              this.tableGoodsData[key].BigImageArr.push(this.tableGoodsData[key].ImageArr[key2].img_url)
            }
          }
        }
      });
    },

    /* 获取列表数据 */
    getTableData() {
      DCapi.get(this.pageInfo.page, this.pageInfo.pagesize,0).then((res) => {
        this.tableData=res.data.data.data_list?res.data.data.data_list:[];
        this.pageInfo.total=res.data.data.total;
        // 设置图片预览
        if (this.tableData.length >0) {
          for (const key in this.tableData) {
            if (typeof(this.tableData[key].BigImageArr) === "undefined") {
              this.tableData[key].BigImageArr = []
            }
            for (const key2 in this.tableData[key].ImageArr) {
              this.tableData[key].BigImageArr.push(this.tableData[key].ImageArr[key2].img_url)
            }
          }
        }
      });
    },

    /* 重置数据 */
    resetFormData(){
      this.formData = {
        id:0,
        store_name:"",
        supervisor:"",
        opening_date:"",
        address:"",
        uploadImgList: [], // 商品图片
        uploadVideoList:[],//视频
      } 
      this.uploadImgList = [] //上传成功的图片
      this.UploadReadyImgList = []//待上传的图片
      this.uploadVideoList = []//上传成功的视频
      this.UploadReadyVideoList = []//待上传的视频
      this.isRepeat = false
    },

    //提交
    submitApp() {
      // 判断是否重复提交
      if (this.isRepeat === false) {
        this.isRepeat = true
        var postData = this.formData
        if(this.formData.uploadImgList.length===0){
          this.$message.error("Please upload at least one image.");
          return false
        }
        if(postData.id > 0){
          // 编辑
          DCapi.edit(postData).then((res) => {
            this.$message.success("submit successfully");
            // this.$router.push("/web/displayCompetition/index");
          }).catch((err) => {
            const data=err.response.data;
            this.$message.error(data.message);
          }).finally(()=>{
            this.getTableData()
            this.resetFormData()
          })
        } else {
          // 新增
          DCapi.add(postData).then((res) => {
            this.$message.success("submit successfully");
            // this.$router.push("/web/displayCompetition/index");
          }).catch((err) => {
            const data=err.response.data;
            this.$message.error(data.message);
          }).finally(()=>{
            this.getTableData()
            this.resetFormData()
          })
        }
      } else {
        this.$message.error("Please do not resubmit.");
      }
      
    
    },

    /* 列表预览视频 */
    tableDataVideoPreview(url){
      this.dialogVideoUrl=url;
      this.dialogVideoVisible=true;
    },


    // 编辑
    editRow(index,tableData){
      const data = tableData[index]
      console.log(data)
      this.clearImages() //清空旧图片数据
      this.clearVideos() //清空旧视频数据
      // 初始赋值
      this.formData.id = data.id
      this.formData.store_name = data.store_name
      this.formData.supervisor = data.supervisor
      this.formData.opening_date = (data.opening_date? new Date(data.opening_date * 1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit'}).replace(/\//g,'-') :"")
      this.formData.address = data.address
      this.formData.uploadImgList = []
      this.formData.uploadVideoList = []
      // 判断是否存在图片
      if (data.ImageArr) {
        if (data.ImageArr.length>0) {
          for (const key in data.ImageArr) {
            const ImgNameArr = data.ImageArr[key].img_url.split('/')
            this.uploadImgList.push({
              name:ImgNameArr[ImgNameArr.length - 1],
              url:data.ImageArr[key].img_url
            })
            this.formData.uploadImgList.push(data.ImageArr[key].img_url)
          }
        }
      }
      // 判断是否存在视频
      if (data.VideoArr) {
        if (data.VideoArr.length>0) {
          for (const key in data.VideoArr) {
            const VideoNameArr = data.VideoArr[key].video_url.split('/')
            this.uploadVideoList.push({
              name:VideoNameArr[VideoNameArr.length - 1],
              url:data.VideoArr[key].video_url
            })
            this.formData.uploadVideoList.push(data.VideoArr[key].video_url)
          }
        }
      }
    },

    /* 删除单条数据 */
    deleteRow(index,tableData){
      const data = tableData[index]
      let tipText = "确认要删除此条数据？"
        if (this.lang ==="en") {
          tipText = "Are you sure you want to delete this data?"
        }
        this.$confirm(tipText, 'prompt', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        })
          .then((res) => {
            DCapi.del(data.id).then((res) => {
              this.$message.success("submit successfully");
            }).catch((err) => {
              const data=err.response.data;
              this.$message.error(data.message);
            }).finally(()=>{
              this.getTableData()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'undelete'
            })
          })
      
    },

    // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓图片····相关操作↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓/
    handleExceed(files, fileList) {
      if (this.lang ==="en") {
        this.$message.warning(`Limited to selecting `+this.uploadImgLimit+` files, `+files.length+` files were selected this time`)
      } else {
        this.$message.warning(`限制选择`+this.uploadImgLimit+`个文件，本次选择了 `+files.length+`个文件`)
      }
    },
    //清空图片数据
    clearImages(){
      this.uploadImgList = [] 
      this.formData.uploadImgList = []
    },
    // 过滤重复
    filterRepetition(arr) {
      let arr1 = []; //存id
      let newArr = []; //存新数组
      for (let i in arr) {
        if (arr1.indexOf(arr[i].name) == -1) {
          arr1.push(arr[i].name);
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    // 修改 存放要上传的文件列表
    handleFileChange(file, fileList) {
      let isNotAlowUpload = false //不允许上传
      let arr = this.filterRepetition(fileList);
      if (arr.length !== fileList.length) {
        isNotAlowUpload = true
        if (this.lang =="en") {
          this.$message("Uploading duplicate files, filtered duplicate files");
        } else {
          this.$message("上传重复文件，已过滤重复文件");
        }
      }
      //文件判断
      for (const key in arr) {
        if (typeof(arr[key].raw)!=="undefined") {
          // 判断是否超出限制大小10M
         if(arr[key].size / 1024 / 1024 > 10){
            isNotAlowUpload = true
            this.$message.error("The size of each uploaded image should not exceed 10M");
          } 
          // 判断格式是否正确
          let typeExt = arr[key].raw.type
          let typeExtBool = false
          for (const key in this.acceptImgType) {
            if (typeExt.indexOf(this.acceptImgType[key])>-1) {
              typeExtBool = true
            }
          }
          if (typeExtBool===false) {
            isNotAlowUpload = true
            this.$message.error("Please upload images in the following format:"+this.acceptImgType.join("/"));
          }
        } else {
          continue //如果不是新选择的文件，跳过检验
        }
      }

      // 不超过限制才上传
      if (isNotAlowUpload === false) {
        this.UploadReadyImgList = arr;
        this.debounce(this.submitUpload, 500)
      } else {
        // 清空不符合缓存
        let uploadImgEl = this.$refs.uploadImgEl.uploadFiles
        for (const key in uploadImgEl) {
          if (uploadImgEl[key].status ==="ready") {
            this.$refs.uploadImgEl.uploadFiles.splice(key,1)
          }
        }
      }


    },
    // element上传多个文件时，会把每个文件做个单独请求
    // 这里的方法是请求最后一次
    debounce(fn, waits) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        fn.apply(this, arguments); // 把参数传进去
      }, waits);
    },

    // 确定
    async submitUpload() {
      if (this.UploadReadyImgList.length === 0) {
        this.$message.error("Please upload the file");
        return;
      }

      let formData = new FormData(); //  用FormData存放上传文件
      this.UploadReadyImgList.forEach((file) => {
        formData.append("file", file.raw); // file.raw
      });

      // 确定上传 把在上传列表里的文件 合并到formData里面传给后台
      const loading = this.$loading({
        lock: true,
        text: 'Uploading, please wait',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let res = await DCapi.uploadApi(this.uploadPostUrl,formData,JSON.parse(localStorage.getItem("loginWeb"))["token"]);
      if (res.data.error === 0) {
        let data = res.data.data
        for (const key in data) {
          const ImgNameArr = data[key].split('/')
          this.uploadImgList.push({
            name:ImgNameArr[ImgNameArr.length - 1],
            url:data[key]
          })
          this.formData.uploadImgList.push(data[key])
        }
      }
      loading.close()
    },
  
    // 预览图片
    handlePreview(file) {
        this.dialogImageUrl=file.url;
        this.dialogVisible=true;
    },
  
    /**
     * 删除图片
     */
    handleDeleteImg(postData) {
      const loading = this.$loading({
        lock: true,
        text: 'Deleting, please wait',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      DCapi.deleteImg(postData).then((res) => {
          const error  = res.data.error
          if (error === 0) {
          this.$message({
              type: 'success',
              message: 'Successfully deleted!'
          })
          } else {
          this.$message({
              type: 'info',
              message: 'undelete'
          })
          }
      }).finally(()=>{
        this.getTableData()
        loading.close()
      })
    },
  
    /* 删除未提交表单的图片 */
    handleDeleteTestImg(imgArr) {
      const loading = this.$loading({
        lock: true,
        text: 'Deleting, please wait',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      DCapi.DeleteTestImg({ img_arr: imgArr }).then((res) => {
          const  error  = res.data.error
          if (error === 0) {
          this.$message({
              type: 'success',
              message: 'Successfully deleted!'
          })
          } else {
          this.$message({
              type: 'info',
              message: 'undelete'
          })
          }
      }).finally(()=>{
        this.getTableData()
        loading.close()
      })
    },

    /* 删除表单的图片 */
    handleDeleteFormImg(file_url){
      for (const key in this.formData.uploadImgList) {
        if(this.formData.uploadImgList[key] === file_url){
          this.formData.uploadImgList.splice(key, 1) // 移除表单图片
        }
      }
    },


    /**
     * 删除图片之前确认
     */
    handleImgBeforeRemove(file, fileList) {
      const bv = false // 阻止直接移除，采用手动移除
      if (file.url.indexOf('/uploads/test/') > -1) {
        var delArr = []
        for (let index = 0; index < this.uploadImgList.length; index++) {
          if (this.uploadImgList[index].url === file.url) {
            this.uploadImgList.splice(index, 1) // 移除展示的图片
            delArr.push({ imgSrc: file.url })
          }

        }
        if (delArr.length > 0) {
          this.handleDeleteTestImg(delArr) // 删除test路径的图片
        }
      } else {
        let tipText = "此操作将永久删除该文件, 是否继续?"
        if (this.lang ==="en") {
          tipText = "This operation will permanently delete the file. Do you want to continue?"
        }
        this.$confirm(tipText, 'prompt', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        })
          .then((res) => {
            // 移除展示的数组
            for (let index = 0; index < this.uploadImgList.length; index++) {
              if (this.uploadImgList[index].url === file.url) {
                this.uploadImgList.splice(index, 1) // 移除展示的图片
              }
            }
            // 删除图片
            var delArr = []
            delArr.push({imgSrc:file.url})
            this.handleDeleteImg({img_arr: delArr})
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'undelete'
            })
          })
      }
      // 移除表单的数据
      this.handleDeleteFormImg(file.url)
      // 阻止删除
      return bv
    }, 
    // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑图片····相关操作↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑/


    // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓视频···········相关操作↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓/
    handleExceedVideo(files, fileList) {
      if (this.lang ==="en") {
        this.$message.warning(`Limited to selecting `+this.uploadVideoLimit+` files, `+files.length+` files were selected this time`)
      } else {
        this.$message.warning(`限制选择`+this.uploadImgLimit+`个文件，本次选择了 `+files.length+`个文件`)
      }
    },
    //清空视频数据
    clearVideos(){
      this.uploadVideoList = [] 
      this.formData.uploadVideoList = []
    },
    // 过滤重复
    filterRepetitionVideo(arr) {
      let arr1 = []; //存id
      let newArr = []; //存新数组
      for (let i in arr) {
        if (arr1.indexOf(arr[i].name) == -1) {
          arr1.push(arr[i].name);
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    // 修改 存放要上传的文件列表
    handleVideoFileChange(file, fileList) {
      let isNotAlowUpload = false //不允许上传
      let arr = this.filterRepetitionVideo(fileList);
      if (arr.length !== fileList.length) {
        isNotAlowUpload = true
        if (this.lang =="en") {
          this.$message("Uploading duplicate files, filtered duplicate files");
        } else {
          this.$message("上传重复文件，已过滤重复文件");
        }
      }
      //文件判断
      for (const key in arr) {
         // 判断是否超出限制大小300M
        if(arr[key].size / 1024 / 1024 > 300){
          isNotAlowUpload = true
          this.$message.error("The size of each uploaded image should not exceed 300M");
        }
        // 判断格式是否正确
        let typeExt = arr[key].raw.type
        let typeExtBool = false
        for (const key in this.acceptVideoType) {
          if (typeExt.indexOf(this.acceptVideoType[key])>-1) {
            typeExtBool = true
          }
        }
        if (typeExtBool===false) {
          isNotAlowUpload = true
          this.$message.error("Please upload videos in the following format:"+this.acceptVideoType.join("/"));
        }
      }

      // 不超过限制才上传
      if (isNotAlowUpload === false) {
        this.UploadReadyVideoList = arr;
        this.debounce(this.submitVideoUpload, 500)
      } else {
        // 清空不符合缓存
        let uploadVideoEl = this.$refs.uploadVideoEl.uploadFiles
        for (const key in uploadVideoEl) {
          if (uploadVideoEl[key].status ==="ready") {
            this.$refs.uploadVideoEl.uploadFiles.splice(key,1)
          }
        }
      }
    },

    // 确定
    async submitVideoUpload() {
      if (this.UploadReadyVideoList.length === 0) {
        this.$message.error("Please upload the file");
        return;
      }

      let formData = new FormData(); //  用FormData存放上传文件
      this.UploadReadyVideoList.forEach((file) => {
          formData.append("file", file.raw); // file.raw
      });

      // 确定上传 把在上传列表里的文件 合并到formData里面传给后台
      const loading = this.$loading({
        lock: true,
        text: 'Uploading, please wait',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let res = await DCapi.uploadApi(this.uploadPostUrl,formData,JSON.parse(localStorage.getItem("loginWeb"))["token"]);
      if (res.data.error === 0) {
        let data = res.data.data
        for (const key in data) {
          const VideoNameArr = data[key].split('/')
          this.uploadVideoList.push({
            name:VideoNameArr[VideoNameArr.length - 1],
            url:data[key]
          })
          this.formData.uploadVideoList.push(data[key])
        }
      }
      loading.close()
    },
  
    // 预览视频
    handleVideoPreview(file) {
        this.dialogVideoUrl=file.url;
        this.dialogVideoVisible=true;
    },
  
    /**
     * 删除视频
     */
    handleDeleteVideo(postData) {
      const loading = this.$loading({
        lock: true,
        text: 'Deleting, please wait',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      DCapi.deleteVideo(postData).then((res) => {
          const error  = res.data.error
          if (error === 0) {
          this.$message({
              type: 'success',
              message: 'Successfully deleted!'
          })
          } else {
          this.$message({
              type: 'info',
              message: 'undelete'
          })
          }
      }).finally(()=>{
        this.getTableData()
        loading.close()
      })
    },
  
    /* 删除未提交表单的视频 */
    handleDeleteTestVideo(fileArr) {
      const loading = this.$loading({
        lock: true,
        text: 'Deleting, please wait',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      DCapi.DeleteTestVideo({ video_arr: fileArr }).then((res) => {
          const  error  = res.data.error
          if (error === 0) {
          this.$message({
              type: 'success',
              message: 'Successfully deleted!'
          })
          } else {
          this.$message({
              type: 'info',
              message: 'undelete'
          })
          }
      }).finally(()=>{
        this.getTableData()
        loading.close()
      })
    },

    /* 删除表单的视频 */
    handleDeleteFormVideo(file_url){
      for (const key in this.formData.uploadVideoList) {
        if(this.formData.uploadVideoList[key] === file_url){
          this.formData.uploadVideoList.splice(key, 1) // 移除表单视频
        }
      }
    },


    /**
     * 删除视频之前确认
     */
    handleVideoBeforeRemove(file, fileList) {
      const bv = false // 阻止直接移除，采用手动移除
      if (file.url.indexOf('/uploads/test/') > -1) {
        var delArr = []
        for (let index = 0; index < this.uploadVideoList.length; index++) {
          if (this.uploadVideoList[index].url === file.url) {
            this.uploadVideoList.splice(index, 1) // 移除展示的视频
            delArr.push({ videoSrc: file.url })
          }

        }
        if (delArr.length > 0) {
          this.handleDeleteTestVideo(delArr) // 删除test路径的视频
        }
      } else {
        let tipText = "此操作将永久删除该文件, 是否继续?"
        if (this.lang ==="en") {
          tipText = "This operation will permanently delete the file. Do you want to continue?"
        }
        this.$confirm(tipText, 'prompt', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        })
          .then((res) => {
            // 移除展示的数组
            for (let index = 0; index < this.uploadVideoList.length; index++) {
              if (this.uploadVideoList[index].url === file.url) {
                this.uploadVideoList.splice(index, 1) // 移除展示的视频
              }
            }
            // 删除视频
            var delArr = []
            delArr.push({videoSrc:file.url})
            this.handleDeleteVideo({video_arr: delArr})
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'undelete'
            })
          })
      }
      // 移除表单的数据
      this.handleDeleteFormVideo(file.url)
      this.getTableData()
      // 阻止删除
      return bv
    }  
    // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑视频···········相关操作↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑/

  },
};
